<template>
    <div v-if="dataSource && dataSourceType === 'IncomingDocument'"
        :key="document.id"
        class="p-relative"
    >

        <Toolbar 
            :menu="dataSource.Data.Menu" 
            v-on:toolbar-button-click="onToolbarClick"  
        />

        <DocumentPanel :document-info="dataSource.Data.Object" />
        
        <v-tabs
            v-model="tab"
            background-color="transparent"
            slider-size="3"
            class="tab-selector-panel"
            mobile-breakpoint="0"
        >
            <v-tabs-slider color="#E43958"></v-tabs-slider>

            <v-tab 
                v-for="item in pages"
                :key="document.id + item.FormId"
                @click="onTabClicked(item.FormId)"
            >
                <template v-if="item.Count < 0">
                    {{ item.Name }}
                </template> 
                <template v-else>
                    {{ item.Name }}
                    <span class="count" id="attachCounter">{{item.Count}}</span>
                </template>   
            </v-tab>

        </v-tabs>

        <v-tabs-items
            v-model="tab"
            class="tab-content-panel"
        >
            <v-tab-item 
                v-for="item in pages"
                :key="document.id + item.FormId"
            >
                <template v-if="item.FormId == document.id">
                    <v-form class="wrapperForm" ref="form" lazy-validation>
                        <v-row dense>

                            <!--Обертка для секции-->
                            <v-col cols="12" sm="12" md="6" class="section-wrapper">
                                
                                <!--Блок Сведения о регистрации-->
                                <v-card flat v-if="isRegistered">
                                    <v-card-text>
                                        <div class="form-box-title">{{$t("Сведения_о_регистрации")}}</div>
                                        
                                        <!--Поле Журнал регистрации (у пунктов не нужно)-->
                                        <v-row no-gutters v-if="document.Card.TypeC4 !== 3 && isOwnDocument">
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Журнал_регистрации")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div class="onlyReadData">{{ document.Counter ? document.Counter.Name : '' }}</div>
                                            </v-col>
                                        </v-row>

                                        <!--Поле Номенклатура (у пунктов не нужно)-->
                                        <v-row no-gutters v-if="document.Card.TypeC4 !== 3 && isOwnDocument">
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Номенклатура")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{document.Nomenclature ? `${document.Nomenclature.Code} ${document.Nomenclature.Header}` : $t("Не_указано")}}
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <!--Поле Номер регистрации, дата-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Номер_регистрации,_дата")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div class="onlyReadData">{{ concatedRegistrationData  }}</div>
                                            </v-col>
                                        </v-row>

                                        <!--поле Директивный документ (только просмотр)-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Директивный_документ")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div class="onlyReadData">
                                                    {{ document.Card.TypeC4 === 2 ? $t("Да") : $t("Нет") }}
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <!--Поле Регистратор-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Регистратор")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div class="onlyReadData">
                                                    <v-workplace-chip :id="document.Card.RegistratorWorkplaceId" :name="document.Card.RegistratorName" />
                                                </div>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                </v-card>

                                <!--Блок Реквизиты документа-->
                                <v-card flat>
                                    <v-card-text>

                                        <div class="form-box-title">{{$t("Реквизиты_документа")}}</div>

                                        <!--Поле Номер пункта (только для пунктов)-->
                                        <v-row no-gutters v-if="document.Card.TypeC4 === 3">
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Номер_пункта")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{ document.Card.SectionNumber ? document.Card.SectionNumber : $t("Не_указано") }}
                                                </div>
                                                <v-text-field
                                                    v-else
                                                    :value="document.Card.SectionNumber"
                                                    @input="update({ property: 'Data.Object.Document.Card.SectionNumber', value: $event })"
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    maxlength = 10
                                                    :rules="requiredRule"
                                                >
                                                </v-text-field>
                                            </v-col>
                                        </v-row>

                                        <!--поле Вид документа-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Тип_документа") }}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{document.Card.DocumentType ? document.Card.DocumentType.Value : $t("не_указано")}}
                                                </div>
                                                <v-select
                                                    v-else
                                                    :value="document.Card.DocumentType"
                                                    @input="update({ property: 'Data.Object.Document.Card.DocumentType', value: $event})"
                                                    :items="documentTypeItems"
                                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                                    item-value="id"
                                                    return-object
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    :rules="requiredRule"
                                                    append-icon="fas fa-chevron-down"
                                                    :menu-props="{ bottom: true, offsetY: true }"
                                                >
                                                    <template v-slot:prepend-item>
                                                        <v-list-item>
                                                            <v-list-item-content>
                                                                <v-text-field 
                                                                    :placeholder="$t('Поиск')"
                                                                    class="mb-2 autocomplete-search"
                                                                    hide-details
                                                                    dense
                                                                    clearable
                                                                    autofocus
                                                                    v-model="documentTypeSearch"
                                                                >
                                                                </v-text-field>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-select>
                                            </v-col>
                                        </v-row>

                                        <!--поле Характер вопроса-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Характер_вопроса")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{ document.Card.QuestCharacter ? document.Card.QuestCharacter.Value : $t("не_указано") }}
                                                </div>
                                                <v-select
                                                    v-else
                                                    :value="document.Card.QuestCharacter"
                                                    @input="update({ property: 'Data.Object.Document.Card.QuestCharacter', value: $event })"
                                                    :items="questCharacterItems"
                                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                                    item-value="id"
                                                    return-object
                                                    hide-details
                                                    outlined
                                                    dense
                                                    append-icon="fas fa-chevron-down"
                                                    :menu-props="{ bottom: true, offsetY: true }"
                                                >
                                                    <template v-slot:prepend-item>
                                                        <v-list-item>
                                                            <v-list-item-content>
                                                                <v-text-field 
                                                                    :placeholder="$t('Поиск')"
                                                                    class="mb-2 autocomplete-search"
                                                                    hide-details
                                                                    dense
                                                                    clearable
                                                                    autofocus
                                                                    v-model="questCharacterSearch"
                                                                >
                                                                </v-text-field>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-select>
                                            </v-col>
                                        </v-row>

                                        <!--поле Язык документа-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Язык_документа")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">

                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{document.Card.Languages ? documentLanguages.map((x) => x.Value).join(', ') : $t("Не_указано")}}
                                                </div>

                                                <v-autocomplete
                                                    v-else
                                                    v-model="documentLanguages"
                                                    :items="GetReference(1000)"
                                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                                    item-value="id"
                                                    multiple
                                                    return-object
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    :rules="multipleRule"
                                                    append-icon="fas fa-chevron-down"
                                                >
                                                </v-autocomplete>

                                            </v-col>
                                        </v-row>

                                        <!--поле Тип носителя-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Тип_носителя")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">

                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{document.Card.CarrierType ? document.Card.CarrierType.Value : $t("Не_указано")}}
                                                </div>
                                                <v-autocomplete
                                                    v-else
                                                    :value="document.Card.CarrierType"
                                                    @input="update({ property: 'Data.Object.Document.Card.CarrierType', value: $event })"
                                                    :items="GetReference(1024)"
                                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                                    item-value="id"
                                                    return-object
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    :rules="requiredRule"
                                                    append-icon="fas fa-chevron-down"
                                                >
                                                </v-autocomplete>
                                            </v-col>
                                        </v-row>

                                        <!--поле Способ доставки-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Способ_доставки")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">

                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{document.Card.DeliveryType ? document.Card.DeliveryType.Value : $t("Не_указано")}}
                                                </div>
                                                <v-autocomplete
                                                    v-else
                                                    :value="document.Card.DeliveryType"
                                                    @input="update({ property: 'Data.Object.Document.Card.DeliveryType', value: $event })"
                                                    :items="GetReference(1018)"
                                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                                    item-value="id"
                                                    return-object
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    :rules="requiredRule"
                                                    append-icon="fas fa-chevron-down"
                                                >
                                                </v-autocomplete>
                                            </v-col>
                                        </v-row>

                                        <!--поле Количество ЛПЭ (для пунктов не нужно)-->
                                        <v-row no-gutters v-if="document.Card.TypeC4 != 3">
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{ $t("Количество_листов/приложений") }}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">

                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{`${document.Card.ListCount}/${document.Card.AttachCount}`}}
                                                </div>
                                                <div class="more-inputs-in-row" v-else>
                                                    <v-text-field
                                                        :value="document.Card.ListCount"
                                                        @input="update({ property: 'Data.Object.Document.Card.ListCount', value: $event })"
                                                        hide-details
                                                        required
                                                        outlined
                                                        dense
                                                        type="number"
                                                        :rules="integerNotNull"
                                                        class="cust-inputnumber"
                                                        min="0"
                                                    >
                                                    </v-text-field>
                                                    <span>/</span>
                                                    <v-text-field
                                                        :value="document.Card.AttachCount"
                                                        @input="update({ property: 'Data.Object.Document.Card.AttachCount', value: $event })"
                                                        hide-details
                                                        required
                                                        outlined
                                                        dense
                                                        type="number"
                                                        :rules="integerRule.concat(requiredRule)"
                                                        class="cust-inputnumber"
                                                        min="0"
                                                    >
                                                    </v-text-field>
                                                </div>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                </v-card>
                                
                            </v-col>

                            <v-col cols="12" sm="12" md="6" class="section-wrapper">
                                <!--ToDo Панелька статуса документа-->
                                <DocumentStatusCard 
                                    v-if="!document.is_new_record && isRegistered" 
                                    :controlInfo="controlInfo"
                                />

                                <!--Секция Корреспондент-->
                                <v-card flat>
                                    <v-card-text>
                                        <div class="form-box-title">{{$t("Корреспондент")}}</div>

                                        <!--поле Отправитель-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Отправитель")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <div class="icon-click-row-group icrg-align-center">                                                    
                                                    <div v-if="!isViewMode" @click="onCorrespondentSelect">
                                                        <v-icon small v-tooltip.left-center="correspondentSelectTooltip">
                                                            fas fa-edit
                                                        </v-icon>
                                                    </div>
                                                    <div class="onlyReadData">
                                                        <v-enterprise-chip v-if="getCorrespondentName" :name="getCorrespondentName" />
                                                        <div class="chipAreaNoData" v-if="!getCorrespondentName"></div>
                                                    </div>
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <!--поле Исходящий номер, дата-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Исходящий_номер,_дата")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{ concatedOutRegistrationData  }}
                                                </div>
                                                <div v-else class="more-inputs-in-row">

                                                    <v-text-field
                                                        :value="document.Card.OutNumber"
                                                        @input="update({ property: 'Data.Object.Document.Card.OutNumber', value: $event })"
                                                        hide-details
                                                        required
                                                        outlined
                                                        dense
                                                        :rules="requiredRule"
                                                    >
                                                    </v-text-field>

                                                    <span>{{ $t("от_время_редактирование") }}</span>

                                                    <v-menu 
                                                        v-model="outDateMenu"
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="290"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                :value="documentOutDate | formattedDate"
                                                                prepend-icon="mdi-calendar"
                                                                readonly
                                                                v-bind="attrs"
                                                                v-on="on" 
                                                                hide-details
                                                                required
                                                                outlined
                                                                dense
                                                                :rules="requiredRule"
                                                                class="datepick-input"
                                                            >
                                                            </v-text-field>
                                                        </template>

                                                        <v-date-picker
                                                            v-model="documentOutDate"
                                                            @input="outDateMenu = false"
                                                            color="teal"
                                                            :first-day-of-week="1"
                                                        >
                                                        </v-date-picker>
                                                    </v-menu>
                                                    
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <!--поле Номер вышестоящего-->
                                        <v-row no-gutters v-if="concatedHigherRegistrationData">
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Номер_вышестоящего_корреспондента")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <div class="onlyReadData">
                                                    {{ concatedHigherRegistrationData  }}
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <!--поле Вышестоящий орган-->
                                        <v-row no-gutters v-if="concatedHigherRegistrationData && document.Card.HigherName">
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Вышестоящий_корреспондент")}}</label>
                                            </v-col>
                                            <v-col  cols="12" sm="12" md="8">
                                                <div class="onlyReadData">
                                                    <v-enterprise-chip :name="document.Card.HigherName" />
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <!--Поле Исполнитель-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Исполнитель")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{ document.Card.ExecuterName ? document.Card.ExecuterName : $t("Не_указано") }}
                                                </div>
                                                <v-text-field
                                                    v-else
                                                    :value="document.Card.ExecuterName"
                                                    @input="update({ property: 'Data.Object.Document.Card.ExecuterName', value: $event })"
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                >
                                                </v-text-field>
                                            </v-col>
                                        </v-row>

                                        <!--Поле Телефон исполнителя-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Телефон_исполнителя")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{ document.Card.Phone ? document.Card.Phone : $t("Не_указано") }}
                                                </div>
                                                <v-text-field
                                                    v-else
                                                    :value="document.Card.Phone"
                                                    @input="update({ property: 'Data.Object.Document.Card.Phone', value: $event })"
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                >
                                                </v-text-field>
                                            </v-col>
                                        </v-row>

                                        <!--Поле Подписал-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Подписал")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{ document.Card.SignerName ? document.Card.SignerName : $t("Не_указано") }}
                                                </div>
                                                <v-text-field
                                                    v-else
                                                    :value="document.Card.SignerName"
                                                    @input="update({ property: 'Data.Object.Document.Card.SignerName', value: $event })"
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    :rules="requiredRule"
                                                >
                                                </v-text-field>
                                            </v-col>
                                        </v-row>

                                        <!--Поле Дата поступления-->
                                        <v-row no-gutters>
                                            <v-col cols="12" sm="12" md="4">
                                                <label class="f-label">{{$t("Дата_поступления")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="8">
                                                <div class="onlyReadData">
                                                    {{ formatDate(document.CreateDate) }}
                                                </div>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                </v-card>

                            </v-col>

                            <v-col cols="12" sm="12" md="12" class="section-wrapper">

                                <!--Секция Краткое содержание-->
                                <v-card flat>
                                    <v-card-text>
                                        <div class="form-box-title">{{$t("Краткое_содержание")}}</div>

                                        <!--Поле Документ-основание-->
                                        <v-row v-if="document.Card.TypeC4 === 3" class="full-width-row" no-gutters>
                                            <v-col cols="12" sm="12" md="2">
                                                <label class="f-label">{{$t("Документ-основание")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="10">
                                                <div class="onlyReadData">
                                                    <v-document-link
                                                        :isViewMode="isViewMode"
                                                        :id="document.ParentId"
                                                        :type="'Chancellery|Documents.Document'"
                                                        :text="dataSource.Data.Object.MainDocumentName"
                                                    />
                                                </div>
                                            </v-col>
                                        </v-row>

                                        <!--Поле Краткое содержание-->
                                        <v-row class="full-width-row" no-gutters>
                                            <v-col cols="12" sm="12" md="2">
                                                <label class="f-label">{{$t("Краткое_содержание")}}</label>
                                            </v-col>
                                            <v-col cols="12" sm="12" md="10">
                                                <div v-if="isViewMode" class="onlyReadData">
                                                    {{ document.Theme }}
                                                </div>
                                                <v-textarea v-else
                                                    :value="document.Theme"
                                                    @input="update({ property: 'Data.Object.Document.Theme', value: $event })"
                                                    rows="3"
                                                    no-resize
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    :rules="requiredRule"
                                                >
                                                </v-textarea>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>
                                </v-card>

                                <!--Секция На рассмотрение-->
                                <v-card v-if="isViewMode" flat>
                                    <v-card-text>
                                        <div class="form-box-title">{{ $t("На_рассмотрение") }}</div>

                                        <!-- Если документ не контрольный, оставляем как было -->
                                        <template v-if="![1, 2].includes(document.Card.ControlType)">

                                            <v-row class="full-width-row" no-gutters>
                                                <v-col cols="12" sm="12" md="2">
                                                    <label class="f-label">{{ $t("Рассматривающие") }}</label>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="10">

                                                    <div v-if="document.Card.Recipients && document.Card.Recipients.length > 0" class="onlyReadData more-per-lab-wrap">

                                                        <template v-for="recipient in document.Card.Recipients">
                                                            <v-workplace-chip
                                                                :key="recipient.Id"
                                                                :id="recipient.Id"
                                                                :name="recipient.Name"
                                                                :can-delete="recipient.CanDelete"
                                                                :is-employee="recipient.IsEmployee"
                                                            />
                                                        </template>

                                                    </div>

                                                    <div v-else class="onlyReadData">
                                                        {{ $t("Не_указано") }}
                                                    </div>

                                                </v-col>
                                            </v-row>

                                        </template>

                                        <template v-else>

                                            <v-row class="full-width-row" no-gutters>

                                                <v-col cols="12" sm="12" md="2">
                                                    <label class="f-label">{{ $t("Первый_рассматривающий") }}</label>
                                                </v-col>

                                                <v-col cols="12" sm="12" md="10">

                                                    <draggable
                                                        v-if="mainRecipient.length > 0"
                                                        v-model="mainRecipient"
                                                        :group="{ name: 'recipients', pull: false }"
                                                        :class="['more-per-lab-wrap', 'onlyReadData', dragInProcess ? 'isDragClass' : '']"
                                                        draggable=".item-drag:not(.disable-drag-wo)"
                                                        :disabled="!recipientsDragEnabled"
                                                    >
                                                        <v-workplace-chip
                                                            :key="mainRecipient[0].Id"
                                                            :id="mainRecipient[0].Id"
                                                            :name="mainRecipient[0].Name"
                                                            :can-delete="mainRecipient[0].CanDelete"
                                                            :is-employee="mainRecipient[0].IsEmployee"
                                                            :class="['item-drag', 'disable-drag-wo']"
                                                        />
                                                    </draggable>

                                                    <div v-else class="onlyReadData">
                                                        {{ $t("Не_указано") }}
                                                    </div>
                                                    
                                                </v-col>
                                            </v-row>

                                            <v-row class="full-width-row" no-gutters>    

                                                <v-col cols="12" sm="12" md="2">
                                                    <label class="f-label">{{ $t("Рассматривающие") }}</label>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="10">
                                                    
                                                    <draggable
                                                        v-if="otherRecipients.length > 0"
                                                        v-model="otherRecipients"
                                                        :group="{ name: 'recipients' }"
                                                        :sort="false"
                                                        :class="['more-per-lab-wrap', 'onlyReadData']"
                                                        :disabled="!recipientsDragEnabled"
                                                        @start="onDragStart"
                                                        @end="onDragEnd"
                                                    >
                                                        <template v-for="recipient in otherRecipients">
                                                            <v-workplace-chip
                                                                :key="recipient.Id"
                                                                :id="recipient.Id"
                                                                :name="recipient.Name"
                                                                :can-delete="recipient.CanDelete"
                                                                :is-employee="recipient.IsEmployee"
                                                            />
                                                        </template>

                                                    </draggable>

                                                    <div v-else class="onlyReadData">
                                                        {{ $t("Не_указано") }}
                                                    </div>

                                                </v-col>

                                            </v-row>
                                            
                                        </template>

                                    </v-card-text>
                                </v-card>

                            </v-col>

                        </v-row>
                    </v-form>
                </template>

                <template v-else-if="!!item && item.FormId == 'Control'">
                    <ControlTab
                        :document="document"
                        :ref="item.FormId"
                    />
                </template>

                <template v-else-if="!!item">
                    <component
                        :is="$mapComponent(item.FormId)" 
                        :ref="item.FormId" 
                        :id="document.id"
                    />
                </template>

            </v-tab-item>

        </v-tabs-items>

    </div>
</template>

<script>
import i18n from '@/i18n'
import sys from '@/services/system';
import { mapActions, mapGetters } from 'vuex';
import Toolbar from '@/components/Toolbar.vue';
import DocumentPanel from '@/components/DocumentPanel.vue';
import DocumentStatusCard from '@/components/widgets/DocumentStatus';
import ControlTab from '@/components/tabs/static/Control';
import _ from 'lodash';
import draggable from 'vuedraggable'

export default {
    name: "IncomingCard",
    components: {
        Toolbar,
        DocumentPanel,
        DocumentStatusCard,
        ControlTab,
        draggable
    },
    asyncComputed: {
        documentTypeItems: {
            async get () {
                let items = Array.from(await this.$store.dispatch('references/getReference', { id: 1001 }));

                if (this.documentTypeSearch)
                    return items.filter(i => sys.checkSearch(i.Value, this.documentTypeSearch) || i.id == this.document?.Card?.DocumentType?.id);
                else
                    return items;
            },
            default: [],
            watch: ['documentTypeSearch']
        },
        questCharacterItems: {
            async get () {
                let items = Array.from(await this.$store.dispatch('references/getReference', { id: 1003 }));

                if (this.questCharacterSearch)
                    return items.filter(i => sys.checkSearch(i.Value, this.questCharacterSearch) || i.id == this.document?.Card?.QuestCharacter?.id);
                else
                    return items;
            },
            default: [],
            watch: ['questCharacterSearch']
        }
    },
    computed: {
        ...mapGetters('references', ['GetReference']),
        ...mapGetters('auth', { userEnterpriseId: 'getUserEnterpriseId' }),
        ...mapGetters('actionsource', { controlInfo: 'getControlInfo', dataSource: 'getDataSource', dataSourceType: 'getDataSourceType' }),
        ...mapGetters({ 
            requiredRule: 'getRequiredRule',
            multipleRule: 'getMultipleRule',
            integerRule: 'getIntegerRule',
            integerNotNull: 'getIntegerNotNullRule'
        }),
        isOwnDocument() {
            if (this.userEnterpriseId != null)
                return this.userEnterpriseId === this.document.EnterpriseId;

            return true;
        },
        isRegistered() {
            return this.document.RegState == 1 || this.document.RegState == 4;
        },
        isViewMode() {
            return this.dataSource.Data.FormId == "0201011";
        },
        document() {
            return this.dataSource?.Data?.Object?.Document;
        },
        pages() {
            let pages = 
            [
                { Count: -1, FormId: this.document?.id, Name: i18n.t("Карточка") }
            ];

            if (!this.document?.is_new_record)
                pages.push({ Count: -1, FormId: "Control", Name: i18n.t("Контроль") });

            pages = pages.concat(this.dataSource?.Data?.Object?.Pages ?? []);

            //если документ является пунктом убираем вкладку Вложения
            if (this.document?.Card.TypeC4 === 3)
                pages = pages.filter( x => x.FormId != "0202010" )

            return pages;
        },
        concatedRegistrationData() {
            let result = i18n.t("Не_указано");

            if (this.document.Card.RegNumber?.length)
                result = `№ ${this.document.Card.RegNumber}`;

            if (this.document.Card.RegDate)
                result += ` ${this.$t("от_время")} ${this.formatDate(this.document.Card.RegDate)} г.`;

            return result;
        },
        concatedOutRegistrationData() {
            let result = i18n.t("Не_указано");

            if (this.document.Card.OutNumber?.length)
                result = `№ ${this.document.Card.OutNumber}`;

            if (this.document.Card.OutDate)
                result += ` ${this.$t("от_время")} ${this.formatDate(this.document.Card.OutDate)} г.`;

            return result;
        },
        concatedHigherRegistrationData() {
            let result = null;

            if (this.document.Card.HigherNumber?.length)
                result = `№ ${this.document.Card.HigherNumber}`;

            if (this.document.Card.HigherDate)
                result += ` ${this.$t("от_время")} ${this.formatDate(this.document.Card.HigherDate)} г.`;

            return result;
        },
        documentLanguages: {
            get: function() {
                return this.document.Card.Languages?.data_list ?? [];
            },
            set: function(newValue) {
                if (this.document.Card.Languages != null)
                    this.updateSource({ property: 'Data.Object.Document.Card.Languages.data_list', value: newValue });
                else
                    this.updateSource({ property: 'Data.Object.Document.Card.Languages', value: { data_list: newValue } });
            }
        },
        getCorrespondentName() {
            if (this.document.Card.Correspondent && this.document.Card.CorrespondentId === this.document.Card.Correspondent.id)
                return this.document.Card.Correspondent.Name;
            else
                return this.correspondentName;
        },
        //#region Подсказки
        correspondentSelectTooltip() {
            return i18n.t("Выберите_организацию");
        },
        //#endregion
        //#region Даты
        documentOutDate: {
            get: function() {
                if (this.document.Card.OutDate)
                    return this.$moment(this.document.Card.OutDate).format('YYYY-MM-DD');
                
                return this.document.Card.OutDate;
            },
            set: function(newValue) {
                this.updateSource({ property: 'Data.Object.Document.Card.OutDate', value: `/Date(${Date.parse(newValue)})/` });
            }
        },
        //#endregion
        //#region Draggable
        recipientsDragEnabled() {
            return this.dataSource?.Data?.Object?.CanMoveRecipient === true;
        },
        mainRecipient: {
            get: function() {
                return this.document?.Card?.Recipients?.length > 0 ? [ this.document.Card.Recipients[0] ] : [];
            },
            set: async function(v) {
                let originalRecipients = Array.from(this.document.Card.Recipients);
                let recipients = Array.from(originalRecipients);
                let oldMainRecipient = recipients[0];
                let indexOfNewMainRecipientInSetterValue = 1 - v.indexOf(oldMainRecipient);
                let newMainRecipient = v[indexOfNewMainRecipientInSetterValue];                
                let index = recipients.indexOf(newMainRecipient);
                recipients.splice(index, 1, oldMainRecipient);
                recipients.splice(0, 1, newMainRecipient);
                this.updateSource({ property: 'Data.Object.Document.Card.Recipients', value: recipients });
                    
                let onOk = async () => {
                    await this.$store.dispatch('actionsource/changeMainRecipient', recipients.map(x => x.Id));
                };

                let onCancel = () => {
                    this.updateSource({ property: 'Data.Object.Document.Card.Recipients', value: originalRecipients });
                };

                if (this.document.Card.Periodicity > 0) {
                    let checkCompleted = await this.$store.dispatch('actionsource/checkNewMainRecipient', newMainRecipient.Id);
                    let message = null;

                    if (checkCompleted instanceof Error)
                        message = i18n.t("Не_удалось_провести_проверку_получателя_value.message", { value: newMainRecipient.Name }) + 'Пожалуйста, обратитесь к администратору системы.';

                    if (!checkCompleted)
                        message = i18n.t("value_не_может_быть_указан_в_качестве_первого_рассматривающего.message", { value: newMainRecipient.Name })

                    if (message) {
                        this.$notify.confirm(message, onCancel, false);
                        return;
                    }
                }

                this.$notify.confirm(
                    this.$t('Вы_действительно_хотите_указать_value_как_первого_рассматривающего.message', { value: newMainRecipient.Name }),
                    onOk,
                    onCancel
                );
            }
        },
        otherRecipients: {
            get: function() {
                return _.tail(this.document.Card.Recipients);
            },
            set: function() {
                return;
            }
        }
        //#endregion
    },
    filters: {
        formattedDate: function (value) {
            var formatedDate = sys.dateFormat(value, 'DD.MM.YYYY');
            return formatedDate ?? "";
        }
    },
    data () {
        return {
            tab: null,
            //для выбора даты исходящего в режиме редактирования
            outDateMenu: false,
            //для выбора корреспондента
            correspondentName: null,
            questCharacterSearch: "",
            documentTypeSearch: "",

            dragInProcess: false,
        }
    },
    methods: {
        ...mapActions('actionsource', { updateSource: 'updateDataSource' }),
        //обработка клика по вкладкам документа
        onTabClicked (formId) {
            if (formId === this.document?.id) return;

            let component = this.$refs[formId]?.[0];

            if (component)
                if (typeof component.update === 'function')
                    component.update();
        },
        //обработка клика по тулбару и маппинг в действия хранилища
        async onToolbarClick (event, button) {
            let actions = Reflect.ownKeys(this.$store._actions).filter(i => i.includes('actionsource/')).map(i => {return i.split('/')[1]})
            
            if (actions.includes(button.Action)) {
                if(button.Action == 'Save' || button.Action == 'SaveAndClose'){
                    let validate_result = this.$refs.form[0].validate();
                    if(validate_result && this.getCorrespondentName){
                        await this.$store.dispatch(`actionsource/${button.Action}`, {event, button});        
                    }else{
                        this.$notify.alert(i18n.t("Не_заполнены_обязательные_поля"));
                    }
                }
                else
                {
                    await this.$store.dispatch(`actionsource/${button.Action}`, {event, button});
                }
            }
            else
                this.$notify.alert(`Действие_${button.Action}_не_реализовано.`);
        },
        formatDate (source) {
            return this.$moment(source).format('DD.MM.YYYY');
        },        
        async onCorrespondentSelect() {
            try {
                let selectMemberParams =  { 
                    title: i18n.t("Выбор_отправителя"),
                    includeOuterEnterprises: true,
                    includeJuridical: true,
                    multiple: false,
                    selected: [ this.document.Card.CorrespondentId ]
                }
                let { enterprise, name } = await this.$store.dispatch('dialogs/selectMembersNew/open', selectMemberParams);
                this.updateSource({ property: 'Data.Object.Document.Card.CorrespondentId', value: enterprise });
                this.correspondentName = name;
            }
            catch (ex) {
                console.log(ex);
            }
        },
        onDragStart() {
            this.dragInProcess = true;
        },
        onDragEnd() {
            this.dragInProcess = false;
        },
        update: _.debounce(function (object) {
            this.updateSource(object);
        }, 250)
    },
    async created() {        
        (async() => {
            while(typeof this.$refs.form === 'undefined')
                await new Promise(resolve => setTimeout(resolve, 100));

            this.$refs?.form?.[0]?.validate();
        })();
    },
    updated() {
        if (this.dataSource !== null) {
            this.$refs?.form?.[0]?.validate();
        }  
    },
    mounted() {        
        this.$eventBus.$on('set-active-tab', async ({ FormId }) => {
            let page = this.pages.find(x => x.FormId === FormId);

            if (page) {
                let targetIndex = this.pages.indexOf(page);

                if (this.tab != targetIndex)
                    this.tab = targetIndex;
                else
                    this.$eventBus.$emit('update-active-tab');
            }
        });
    },
    beforeDestroy() {
        this.$eventBus.$off('set-active-tab');
    }
}
</script>